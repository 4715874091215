import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";
import Dogpage from './Dogpage'

function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route index element={<Dogpage />} />

                {/* <Route path={"/dognutrition"} exact element={<Dogpage/>} /> */}

            </Routes>
        </Router>
    );
}

export default AppRouter; 