import React from "react";

function Button(props) {
    const centerImage = props.centerImage || false;
    return (
        <div onClick = {props.onClick} className={props.className} >
        {centerImage ? <img className= {props.imageStyle} src={props.imageURL} alt={props.imageAlt} />: null}
        </div>
    )
}

export default Button;