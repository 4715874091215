export const geolocationApiKey = 'xxtD86y5TVCXPNo7PAOH7NHImkvw7EUvBIKYEOm6Kv4'

export const phCountryName = "Philippines"
export const thCountryName = "Thailand"
export const vnCountryName = "Vietnam"
export const sgCountryName = "Singapore"
export const idCountryName = "Indonesia"
export const myCountryName = "Malaysia"

export const phCountryISO = "PH"
export const thCountryISO = "TH"
export const vnCountryISO = "VN"
export const sgCountryISO = "SG"
export const idCountryISO = "ID"
export const myCountryISO = "MY"

//Pedigree redirections
export const pedigree_ph = "https://www.pedigree.com.ph/"
export const pedigree_th = "https://www.pedigree.co.th/"
export const pedigree_vn = "https://www.pedigree.com.vn/"
export const pedigree_sg = "https://www.pedigree.com.my/"
export const pedigree_id = "https://www.pedigree.id/"
export const pedigree_my = "https://www.pedigree.com.my/"

//Cesar redirections
export const cesar_ph = "https://www.cesar.com.ph/"
export const cesar_th = "https://www.cesar.co.th/"
export const cesar_vn = "https://www.cesar.com.ph/"
export const cesar_sg = "https://www.cesarmalaysia.com/"
export const cesar_id = "https://www.cesar.co.id/"
export const cesar_my = "https://www.cesarmalaysia.com/"

//IAMS redirections 
export const iams_ph = "https://ph.iams.asia/"
export const iams_th = "https://th.iams.asia/"
export const iams_vn = "https://ph.iams.asia/"
export const iams_sg = "https://sg.iams.asia/"
export const iams_id = "https://id.iams.asia/"
export const iams_my = "https://my.iams.asia/"

