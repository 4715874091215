// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select {
  /* appearance: none; */
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  /* background: url('arrow.png') no-repeat right center; */
  padding-right: 20px;
  border-radius: 4px;
  font-size: 16px;
  background-color: '#0c3468';
  border: none;
  
  /* background-color: black; */

}

select::-ms-expand {
  display: none;
}

select option {
  /* background-color: '#003399'; */
  color: '#ffffff';
}

.dropdown-wrapper{
  width: 100%;
  max-width: 300px;
}

@media (max-width:375px){
  .dropdown-wrapper{
    max-width: 150px;
  }
  select option {
    font-size: 10px;
  }

}

@media (min-width:376px) and (max-width:575.98px){
  .dropdown-wrapper{
    max-width: 200px;
  }
  select option {
    font-size: 10px;
  }

}

@media (min-width:576px) and (max-width:767.98px){
  select option {
    font-size: 10px;
  }
}

@media (min-width:768px) and (max-width:991.98px){
  select option {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/DropdownComponent.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,8BAA8B;EAC9B,2BAA2B;EAC3B,yDAAyD;EACzD,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,2BAA2B;EAC3B,YAAY;;EAEZ,6BAA6B;;AAE/B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,eAAe;EACjB;;AAEF;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,eAAe;EACjB;;AAEF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["select {\n  /* appearance: none; */\n  /* -webkit-appearance: none; */\n  /* -moz-appearance: none; */\n  /* background: url('arrow.png') no-repeat right center; */\n  padding-right: 20px;\n  border-radius: 4px;\n  font-size: 16px;\n  background-color: '#0c3468';\n  border: none;\n  \n  /* background-color: black; */\n\n}\n\nselect::-ms-expand {\n  display: none;\n}\n\nselect option {\n  /* background-color: '#003399'; */\n  color: '#ffffff';\n}\n\n.dropdown-wrapper{\n  width: 100%;\n  max-width: 300px;\n}\n\n@media (max-width:375px){\n  .dropdown-wrapper{\n    max-width: 150px;\n  }\n  select option {\n    font-size: 10px;\n  }\n\n}\n\n@media (min-width:376px) and (max-width:575.98px){\n  .dropdown-wrapper{\n    max-width: 200px;\n  }\n  select option {\n    font-size: 10px;\n  }\n\n}\n\n@media (min-width:576px) and (max-width:767.98px){\n  select option {\n    font-size: 10px;\n  }\n}\n\n@media (min-width:768px) and (max-width:991.98px){\n  select option {\n    font-size: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
