// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  /* background: #fff; */
  /* background: linear-gradient(180deg, #ffffff 0%, #003399 90%) fixed center no-repeat; */
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  /* background: linear-gradient(180deg, #ffffff 0%, #003399 90%) ; */
  /* background-attachment: scroll;
  background-position: fixed; */
  background-size: auto;
}

/* .wave::before {
  content: "";
  position: absolute;
  border-radius: 100% 50%;
  background-color: #ffffff;
  opacity: 0.7;
  width: 120%;
  height: 200%;
  top: -120%;
  left: -10%;
  transform: rotate(-10deg);
} */

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,yFAAyF;AAC3F;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,aAAa;EACb,WAAW;EACX,mEAAmE;EACnE;+BAC6B;EAC7B,qBAAqB;AACvB;;AAEA;;;;;;;;;;;GAWG","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: white;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  /* background: #fff; */\n  /* background: linear-gradient(180deg, #ffffff 0%, #003399 90%) fixed center no-repeat; */\n}\n\n.wave {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: -1;\n  /* background: linear-gradient(180deg, #ffffff 0%, #003399 90%) ; */\n  /* background-attachment: scroll;\n  background-position: fixed; */\n  background-size: auto;\n}\n\n/* .wave::before {\n  content: \"\";\n  position: absolute;\n  border-radius: 100% 50%;\n  background-color: #ffffff;\n  opacity: 0.7;\n  width: 120%;\n  height: 200%;\n  top: -120%;\n  left: -10%;\n  transform: rotate(-10deg);\n} */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
